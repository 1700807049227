const config = {
    apiKey: "AIzaSyCb7ZV1LMhecurGE0rg2gLzjWTq7xaSY7k",
    authDomain: "ftl-hackathon-d36a2.firebaseapp.com",
    databaseURL: "https://ftl-hackathon-d36a2.firebaseio.com",
    projectId: "ftl-hackathon-d36a2",
    storageBucket: "ftl-hackathon-d36a2.appspot.com",
    messagingSenderId: "639213115313",
    appId: "1:639213115313:web:bc24350e441e4c029c4f90",
    measurementId: "G-MJ8BL1KHZW"
  };

  export default config;